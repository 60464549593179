@import '../../semantic-ui/variables.less';

.table-container {
    &__table {
        padding-top: 16px;
        padding-bottom: 16px;
    }
    &__without-header {
        font-size: 13px;
        border: none !important;
        border-radius: 0 !important;

        thead {
            tr {
                th {
                    padding: 14px 32px !important;

                    &:first-child {
                        text-align: left !important;
                    }
                }
            }
        }

        tr {
            td {
                padding: 14px 32px !important;

                &:first-child {
                    display: flex;
                    justify-content: left !important;
                    font-weight: 600 !important;
                }
            }
        }

        & .table-container__table {
            padding-top: 0 !important;
        }

        & .ui.table tbody tr:last-child td {
            border-bottom: none !important;
        }
    }

    &__without-bold-first-column {
        tr {
            td {
                &:first-child {
                    font-weight: 500 !important;
                }
            }
        }
    }

    .ui.table {
        border: none !important;
        margin: 0 !important;
        border-radius: 12px !important;

        &.fixed {
            table-layout: fixed;

            & th {
                overflow: visible !important;
            }
        }

        thead {
            tr {
                th {
                    background-color: @white-500 !important;
                    border: none !important;
                    font-weight: 600;

                    &:first-child {
                        text-align: center;
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    vertical-align: middle;
                }
            }
            tr.blocked, tr.blocked a{
                color: #D9E1E1 !important;
            }
        }

        tfoot {
            height: 62px !important;
        }

        td.no-result-text {
            text-align: center;
        }
    }

    background: @white-500;
    border-radius: 12px;
    border: 1px solid @border-color;
    min-height: 100px;

    &__header {
        padding: 0 96px 0 32px;
        border-bottom: 1px solid @border-color;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-title {
            color: @dark-500;
            font-size: 18px;
        }

        &-left-side {
            display: flex;
            align-items: center;
            gap: 20px;
        }

        &-right-side {
            border-left: 1px solid @border-color;
            position: relative;
            width: 260px;
            height: 55px;


            &-search {
                height: 55px;
                width: 100%;
                position: absolute;
                background-color: @white-500;
                display: flex;

                & .input {
                    width: 100%;

                    & input {
                        border: none !important;
                        font-size: 13px;
                        font-weight: 500;
                    }
                }
            }
        }

        &-buttons {
            display: flex;
            width: 55px;
            flex-direction: row;
            justify-content: space-between;
        }

        &-button {
            width: 24px;
            height: 24px;
            cursor: pointer;
            background-image: url('../images/add-button.svg');
            transition: background-image .15s ease-in-out;
            background-repeat: no-repeat;
            
            &:hover {
                background-image: url('../images/add-button-hover.svg');
            }
        }

        &-download-button {
            width: 24px;
            height: 24px;
            margin-top: 1px;
            cursor: pointer;
            background-image: url('../images/download-button.svg');
            transition: background-image .15s ease-in-out;
            background-repeat: no-repeat;

            &:hover {
                background-image: url('../images/download-button-hover.svg');
            }
        }
    }

    &__table {
        padding-top: 16px;
        min-height: 160px;
        display: flex;
        justify-content: center;
        align-items: center;

        &-empty-message {
            width: 100%;
            &-text {
                background: @gray-10;
                padding: 16px;
                margin: 0 20px;
                text-align: center;
                font-weight: 500 !important;
            }
        }
    }

    &__footer {
        border-top: 1px solid @border-color !important;
        height: 62px !important;
    }

    &_children {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;

        border-bottom-left-radius: 12px !important;
        .ui.modal > :last-child {
            border-bottom-right-radius: 12px !important;
        }
    }
}
