@import '../../../../semantic-ui/variables.less';

.date-cargo-collection-modal-form {
	min-height: 130px;
	margin-left: 20% !important;
	margin-top: 0 !important;

    &-wrapper {
        border: 1px solid transparent;
    }

	&__row {
		margin: auto;
		padding-top: 0 !important;
		padding-bottom: 14px !important;

		&:last-child {
			padding-bottom: 0 !important;
		}
	}

	&__column {
		font-size: 13px;
		font-weight: @label-regular-weight;
		line-height: 1.5;
		display: flex !important;
		align-items: center;

		&-field {
			width: 120px;
			&:first-child {
				margin-left: 10px;
				margin-right: 10px;
			}
            &:last-child {
                margin-left: 10px;
            }

            input {
                height: 31px !important;
            }
		}
	}

	&__errors-wrapper {
		padding: 0 0 20px;
		color: @color-option-3;
		font-size: 11px;
		font-weight: 600;
		line-height: 1.5;
		text-align: center;
		margin-top: -31px;
	}

	&__errors-wrapper:empty {
		margin-top: 0;
	}

	&__error {
		display: block;
	}
}
