@import '../../variables.less';

.notification {
	display: flex;
	align-items: flex-start;
	width: 100%;
	padding: 18px 24px;
	border-radius: 12px;
	margin-top: 13px;
	background: @white-500;
	padding: 18px 24px;
	box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);

	&__content {
		flex: 1;
	}

	&__content-header {
		display: flex;
		align-items: center;
		height: 32px;
	}

	&__status {
		width: 11px;
		height: 11px;
		border-radius: 50%;
		margin-right: 7px;

		&_info {
			background: #17a2b8;
		}

		&_warning {
			background: @color-option-4;
		}

		&_danger {
			background: @color-option-3;
		}

		&_success {
			background: @color-option-1;
		}
	}

	&__link {
		font-weight: bolder;
		margin: 0;
		&::first-letter {
			text-transform: uppercase;
		}
	}

	&__message {
		margin-left: 17px;
		font-size: 12px;
	}

	&__close-button {
		width: 40px;
	}
}
