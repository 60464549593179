@import '../../../../semantic-ui/variables.less';

.cargo-pickup-badge {
	display: flex;
	align-items: center;
	height: 19px;
	padding: 0 5px;
	border-radius: 4px;
	background-color: @dark-5;
	color: @dark-80;
	font-family: Montserrat;
	font-size: 11px;
	font-weight: 500;
	line-height: 128.9%;
}
