.text-icon {
	position: absolute;
	right: 0;

	.input-group-text {
		display: block;
		align-items: center;
		padding: 0 !important;
		font-size: 10px;
		font-weight: 600;
		font-style: normal;
		line-height: 30px;
		width: 30px;
		height: 30px;
		text-align: center;
		color: #212529;
		text-decoration: none;
		white-space: nowrap;
		background-color: rgba(160, 169, 166, 0.05);
		border-left-color: rgb(206, 212, 218);
		border-left-style: solid;
		border-left-width: 1px;
		border-right-color: rgb(206, 212, 218);
		border-right-style: solid;
		border-right-width: 1px;
		border-top-color: rgb(206, 212, 218);
		border-top-left-radius: 0px;
		border-top-right-radius: 5.25px;
		border-top-style: solid;
		border-top-width: 1px;
		border-bottom-color: rgb(206, 212, 218);
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 5.25px;
		border-bottom-style: solid;
		border-bottom-width: 1px;
	}
}
