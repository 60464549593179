@import '../../../../semantic-ui/variables.less';

.cargo-delivery-badge {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	padding: 0px 5px;
	border-radius: 4px;
	background-color: @dark-5;
	color: @dark-80;
	font-family: Montserrat;
	font-size: 11px;
	font-weight: 500;
	line-height: 128.9%;
}
