@import '../../../../semantic-ui/variables.less';

.reset-password {
	padding: 30px;
	display: flex;
	flex-direction: column;
	border-radius: 12px;
	min-height: 340px;
	gap: 21px;

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__title {
		font-size: 24px;
		font-weight: 600;
		margin-bottom: 4px;
		font-family: @header-5-bold-family;
	}

	&__text {
		font-size: 14px;
		font-weight: @body-1-regular-weight;
		font-style: @body-1-regular-style;
		font-family: @body-1-regular-family;
		color: @gray-500;
		line-height: 24px;
	}

	&__field {
		min-height: 51px;
	}

	&__submit-button {
		height: 58px;
		padding: 18px 20px !important;
		border-radius: 10px !important;
		background: @primary-500 !important;
		color: @white-500 !important;
		font-size: 14px !important;
		font-weight: @body-1-bold-weight !important;
		font-style: @body-1-bold-style !important;
		font-family: @body-1-bold-family !important;
		margin-right: 0 !important;
	}
}
