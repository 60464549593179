.marker-cell {
	&__text {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		padding: 0;
		margin-left: 10px;
		width: 24px;
		height: 24px;
		border: none;
		border-radius: 0;
		background-image: url('../../../../components/images/marker-icon.svg');
		transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, background-image 0.15s ease-in-out,
			border-color 0.15s ease-in-out;
	}

	&:hover {
		.marker-cell__text {
			background-image: url('../../../../components/images/marker-active-icon.svg');
		}
	}
}
