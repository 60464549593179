@import '../../semantic-ui/variables.less';

.custom-cell {
    &__contact {
        &-phone {
            padding-top: 3px;
            font-size: 12px;
        }
    }
    &__id {
        margin: 0 auto;
        display: flex;
        justify-content: center;
    }

    &__link:hover {
		color: @primaryColor !important;
	}
}