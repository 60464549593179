@import '../../../../../semantic-ui/variables.less';

.car-form {
	margin: 0 !important;
	padding: 20px 0 !important;

	&__row {
		margin: 0 !important;
		padding-top: 0 !important;
		padding-bottom: 14px !important;

		&:last-child {
			padding-bottom: 0 !important;
		}
	}

	&__column {
		font-size: 13px;
		font-weight: @label-regular-weight;
		line-height: 1.5;

		&:first-child {
			display: flex !important;
			justify-content: flex-end;
			align-items: center;
		}

		&-with-button {
			display: inline-flex;
			align-items: center;
			width: 100%;

			& img {
				padding-left: 10px;
				cursor: pointer;
			}
		}

		&_with-select {
			max-width: 355px;
		}
	}

	&__field-with-button {
		width: 100%;
	}

	&__add-button {
		width: 26px;
		height: 24px;
		margin-left: 12px;
		cursor: pointer;
		background-image: url('../../../../../components/images/add-button.svg');
		transition: background-image 0.15s ease-in-out;

		&:hover {
			background-image: url('../../../../../components/images/add-button-hover.svg');
		}
	}
}
