@import '../../../../semantic-ui/variables.less';

.claims-table {
    width: 100%;

    &__table {
        margin: 0 !important;

        &-row {
            border-top: 1px solid @border-color !important;
        }

        &-cell {
            padding: 14px 32px !important;
            vertical-align: top !important;
            color: @dark-80;
            font-size: 12px;

            &:first-child {
                width: 100px;
                color: @dark-500;
                font-weight: 600;
            }
        }
    }

    &__no-data {
        text-align: center !important;
        padding: 16px !important;
        color: @dark-500;
    }
} 