@import '../../../../semantic-ui/variables.less';

.refusal-reason-form {
	min-height: 140px;
	margin-left: 10% !important;
	margin-top: 0 !important;
	margin-bottom: 5px !important;

	&-wrapper {
		border: 1px solid transparent;
	}

	&__row {
		margin: auto;
		padding-top: 0 !important;
		padding-bottom: 14px !important;

		&:last-child {
			padding-bottom: 0 !important;
		}
	}

	&__column {
		font-size: 13px;
		font-weight: @label-regular-weight;
		line-height: 1.5;
		display: flex !important;

		&-field {
			width: 100%;
			&:last-child {
				margin-left: 10px;
			}
		}
	}
}
