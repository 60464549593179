@import '../../../../semantic-ui/variables.less';

.document-attachment-form {
	min-height: 61px;

	&__row {
		min-height: 61px;
		display: flex;
		align-items: center;
		padding: 5px 32px;
		border-bottom: 1px solid @border-color;

		&:last-child {
			border-bottom: none;
		}
	}

	&__files {
		width: 50%;
		display: flex;
		align-items: center;
		gap: 10px;
	}

	&__column {
		width: 50%;
	}

	&__inner-row {
		display: flex;
		align-items: center;
		gap: 10px;
	}

	&__document {
		&-text {
			font-size: 12px;
			font-family: Montserrat;
			color: @dark-500;

			&_bold {
				font-weight: 600;
			}
		}
		&-name {
			padding: 0 18px;
			width: 100%;
		}
	}

	&__error {
		color: @color-option-3;
		font-size: 11px;
		font-weight: 600;
	}
}
