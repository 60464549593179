@import '../../../variables';

.modal-footer-buttons {
    display: flex;
    justify-content: space-between;
    padding: 20px 32px;

    &__left, &__right {
        display: flex;
        gap: 1rem;
    }

    &__button {
        border: 1px solid;
        border-radius: 12px !important;
        font-size: 13px;
        padding: 8px 20px;
        color: @dark-500 !important;
        line-height: 1.5;
        cursor: pointer;
        font-weight: 500;
        transition: color .15s ease-in-out, background-color .15s ease-in-out, background-image .15s ease-in-out, border-color .15s ease-in-out;

        &-primary {
            border-color: @color-option-1;
            &:hover {
                color: @white-500 !important;
                background-color: @color-option-1;
            }
        }

        &-success {
            border-color: @color-option-1;
            &:hover {
                color: @white-500 !important;
                background-color: @color-option-1;
            }
        }

        &-secondary {
            border-color: @gray-500;
            &:hover {
                color: @white-500 !important;
                background-color: @gray-500;
            }
        }

        &-warning {
            border-color: @color-option-3;
            &:hover {
                color: @white-500 !important;
                background-color: @color-option-3;
            }
        }

        &-info {
            border-color: @primary-500;
            &:hover {
                color: @white-500 !important;
                background-color: @primary-500;
            }
        }

        &-info-revers {
            background-color: @primary-500;
            border-color: @primary-500;
            color: @white-500 !important;
            &:hover {
                color: @dark-500 !important;
                background-color: transparent;
            }
        }

        &-disabled {
            cursor: default;
            pointer-events: none;
            filter: opacity(0.5);
        }
    }
}