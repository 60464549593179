@import '../../../../semantic-ui/variables.less';

.profile-content {
    height: 285px;
    overflow-y: auto;

    &__table {
        &-row {
            border-bottom: 1px solid @border-color !important;
        }

        &-cell {
            padding: 14px 32px !important;
            font-size: 13px;
            color: @dark-80;

            &:first-child {
                font-weight: @label-bold-weight;
            }

            //&_company {
            //    display: flex;
            //    flex-direction: column;
            //    cursor: pointer;
            //}
        }
    }
}