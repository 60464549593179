@import '../../../../../semantic-ui/variables.less';

.product-invoice-form {
	border: 1px solid transparent;
	&__loader {
		position: relative;
		height: 110px;
	}

	&__content {
		min-height: 140px;
		margin-left: 20% !important;
		margin-top: 0 !important;
		margin-bottom: -5px !important;

		&-row {
			margin: auto;
			padding-top: 0 !important;
			padding-bottom: 14px !important;

			&:last-child {
				padding-bottom: 0 !important;
			}
		}

		&-column {
			font-size: 13px;
			font-weight: @label-regular-weight;
			line-height: 1.5;
			display: flex !important;

			&-field {
				width: 100%;
				&:last-child {
					margin-left: 10px;
				}
			}
		}
	}
}
