@import '../../semantic-ui/variables.less';

.page-content-with-nav {
	width: 100%;

	&__nav {
		font-size: 15px;
		display: flex;
		gap: 10px;
		position: relative;
		margin-bottom: 20px;
		line-height: 1.5;
		vertical-align: super;

		&-item {
			text-decoration: none !important;
			padding: 0 10px 10px 10px;

			&:hover &-text {
				color: @primary-500 !important;
			}

			&-count {
				background-color: @gray-80;
				border-radius: 4px;
				font-size: 9px;
				font-weight: 700;
				color: @white-500;
				padding: 3px 6px;
				vertical-align: top;
				margin-left: 3px;

				&_active {
					background-color: @secondary-500;
				}
			}

			&_active {
				font-weight: 700;
				border-bottom: 3px solid @primary-500;
			}
		}

		&-line {
			position: absolute;
			bottom: 0;
			height: 3px;
			width: 100%;
			background-color: @gray-20;
		}
	}
}
