@import '../../../../semantic-ui/variables.less';

.claims-custom-modal-cell {
    &__cell {
        display: flex;
        flex-direction: column;
        color: @dark-500;

        &_bold {
            font-weight: 500;
        }

        &_thin {
            color: @dark-80;
            font-size: 12px;
            margin-top: 4px;
        }

       
    }
    &__status {
        display: flex;
        justify-content: space-between;
        width: 90%;
        flex-direction: row;
        gap: 4px;
    }

    &__status_title {
        font-weight: 600;
        width: 100px;
    }

    &__company-container {
        display: flex;
        justify-content: space-between;
        width: 80%;
    }

    &__contact {
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: 30%;
    }
} 