@import '../../../../semantic-ui/variables.less';

.audit-content {
    padding: 0px;
    text-align: center;
    max-height: 600px;
    overflow: auto;

    &__empty-message {
        background: @gray-10;
        padding: 16px;
        text-align: center;
    }

    &__table {
        & td, & th {
            padding: 5px;
        }
    }
}