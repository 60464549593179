@import '../../../../semantic-ui/variables.less';

.user-role-form {
    padding: 15px;
    overflow: auto;

    &__field {
        width: 435px;

        &-wrapper {
            display: flex;
            justify-content: center;
            padding-bottom: 15px;
        }
    }
}