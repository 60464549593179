@import '../../../../../../semantic-ui/variables.less';

.company-form {
	margin: 0 !important;
	padding: 20px 0 !important;

	&__row {
		margin: 0 !important;
		padding-top: 0 !important;
		padding-bottom: 14px !important;

		&:last-child {
			padding-bottom: 0 !important;
		}

		&-title {
			display: flex !important;
			flex-direction: column !important;
			text-align: center !important;
			gap: 8px !important;
		}
	}

	&__column {
		font-size: 13px;
		font-weight: @label-regular-weight;
		line-height: 1.5;

		&:first-child {
			display: flex !important;
			justify-content: flex-end;
			align-items: center;
		}

		&_text-bold {
			font-weight: 600;
		}
	}

	&__title,
	&__second-title {
		font-size: 14px;
		font-weight: 600;
		padding-top: 8px;
	}

	&__second-title {
		padding-top: 24px;
	}

	&__subtitle,
	&__second-subtitle {
		font-size: 12px;
		font-weight: 400;
		padding-bottom: 8px;
		line-height: 1.8 !important;
	}

	&__column-with-multiple-fields {
		display: flex;
		gap: 11px;
		line-height: 30px;
	}

	&__contract-field {
		width: 275px;
		flex-shrink: 0;
	}
}
