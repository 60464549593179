@import '../../../../semantic-ui/variables.less';

.reqs-documents-tab {
    height: 612px;
    overflow-x: auto;

    &__no-data {
        text-align: center !important;
        padding-top: 40px !important;
        font-style: italic;
    }

    &__section {
        &-name {
            font-size: 18px;
            font-weight: 500;
            padding: 18px 0 18px 32px;
            border-bottom: 1px solid @border-color;
            color: @dark-500 !important;
        }

        &-docs {
            &-item {
                display: flex;
                padding: 10px 36px 10px 32px;
                align-items: center;
                border-bottom: 1px solid @border-color;
                justify-content: space-between;
                &-info {
                    display: flex;
                    align-items: center;
                }

                &_disabled {
                    pointer-events: none;
                    cursor: default;
                    color: @dark-20;

                    & img {
                        filter: opacity(0.5) grayscale(1);
                    }
                }
            }
        }
    }

    &__doc {
        &-col {
            &_first {
                display: flex;
                align-items: center;
                gap: 10px;
                font-size: 12px;
                font-weight: 600;
                width: 330px;
            }

            &_second {
                font-size: 12px;
                width: 380px;
            }

            &-item {
                &_bold {
                    font-weight: 600;
                }
            }
            &-link {
                display: block;
                line-height: 0;
                cursor: pointer;

            }
        }

        &-name {
            padding-top: 2px;
        }

        &-description {
            font-size: 12px;
            font-weight: 400;
        }
    }
}