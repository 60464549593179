@import '../../variables.less';

.form-select {

	&__content {
		position: relative;
		display: flex;
	}

	&_with-icon {
		.ui.input > input {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
	}

	&__input {
		width: 100%;

		&_icon {
			width: calc(100% - 30px);
		}
	}

	&__menu {
		width: 100%;
		position: absolute;
		top: 31px;
		left: 0;
		background: @white-500;
		z-index: 99;
		max-height: 200px;
		overflow-y: auto;
		cursor: pointer;

		border: 1px solid rgba(34, 36, 38, 0.15) !important;
		border-radius: .3rem;
		box-shadow: 0 2px 3px 0 #22242626;

		&_with-icon {
			width: calc(100% - 30px);
		}
		&-item {
			padding: 14px;

			&:hover {
				background: @dark-5;
			}

			&:first-child {
				cursor: default;
				font-size: 11px;

				&:hover {
					background: @white-500;
				}
			}
		}
	}

	&__menu:empty {
		box-shadow: none !important;
		border: none !important;
	}

	&__address {
		font-size: 13px;
		display: block;
	}

	&__item-title {
		&_deleted {
			text-decoration: line-through;
		}

		&_bold {
			font-weight: bold;
		}
	}

	&__icon {
		width: 30px;
		height: 30px;
		border-radius: 0 4px 4px 0;
		background: @light-20;
		border: 1px solid @dark-20;
		border-left: none;
		text-align: center;
		line-height: 29px;
		padding-left: 2px;
		& .icon{
			color: @primary-5;
		}
	}
	.validation_error {
		color: @color-option-3;
	}
}
