@import '../../../../../semantic-ui/variables.less';

.registration-form {
	display: flex;
	flex-direction: column;
	padding: 14px;
	max-height: 602px;
	overflow-y: auto;

	&__section {
		margin-bottom: 42px;

		&:first-child {
			margin-top: 14px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__section-header {
		min-height: 44px;
		text-align: center;
		margin-bottom: 14px;
	}

	&__section-title {
		font-family: @body-1-bold-family;
		font-size: 14px;
		font-weight: 600;
		margin-bottom: 7px;
	}

	&__header-text {
		width: 40%;
		font-family: @body-1-regular-family;
		font-size: 12px;
		font-weight: 400;

		&_block {
			width: 100%;
			display: block;
			text-align: center;
		}
	}

	&__section-row {
		display: flex;
		align-items: center;
		margin-bottom: 14px;

		&:last-child {
			margin-bottom: 0;
		}

		&_bottom {
			align-items: start;
		}
	}

	&__section-text {
		width: 40%;
		max-width: 292px;
		font-family: @body-1-regular-family;
		font-size: 13px;
		font-weight: 400;
		padding: 6px 10px;

		&-documents {
			font-family: @body-1-bold-family;
			font-size: 12px;
			font-weight: 400;

			&_accent {
				text-decoration: underline;
			}
		}

		&_right {
			text-align: right;
		}

		&_bold {
			font-weight: bolder;
		}
	}

	&__section-field,
	&__section-phone-field {
		width: 60%;
		max-width: 437px;
		padding: 0 10px;
	}

	&__phone {
		width: 50%;
	}

	&__extension {
		width: 50%;
		margin-left: -1px;
	}
}
