@import '../../variables.less';

.form-checkbox {

    .ui.toggle.checkbox, .ui.toggle.checkbox input {
        width: 29px !important;
        height: 16px !important;
        min-height: 16px !important;
        padding-top: 3px;
    }

    .ui.toggle.checkbox label {
        width: 29px;
        height: 16px;
      min-height: 16px !important;
    }

    .ui.toggle.checkbox input ~ label:after {
        box-shadow: none !important;
        background: @white-500;
        width: 14px;
        height: 14px;
        top: 1px;
        left: 1px !important;
    }

    .ui.toggle.checkbox.checked input ~ label:after {
        left: 14px !important;
    }

    .ui.toggle.checkbox input ~ label:before {
        width: 29px;
        height: 16px;
        background-color: @dark-20;
    }

		.ui.checkbox:not(.toggle) input ~ label:before {
			width: 22px;
			height: 22px;
			background: @white-500;
			border: 1px solid #292d32;
			border-radius: 6px;
		}

		.ui.checkbox.checked:not(.toggle) input ~ label:before{
			 border: 1px solid #292d32;
			}

			.ui.checkbox.checked:not(.toggle) input ~ label:after{
				margin-left: 2px;
				margin-top: 2px;
			 }

    //.ui.checkbox.checked input ~ label:before{
    //  border: 1px solid @primary-500;
    //}
    //
    ////.ui.checkbox.checked input:focus ~ label:after {
    ////  color: @InputBackgroundColorLight;
    ////}
    //
    ////.ui.checkbox input:focus ~ label:before {
    ////  border-color: @CheckboxBorderColor;
    ////}
    //
    //.ui.checkbox label {
    //  padding-left: 36px;
    //  //color: @TextColorLight;
    //  font-size: 14px;
    //  line-height: 20px;
    //}
    //
    //.ui.slider.checkbox label:after,
    //.ui.toggle.checkbox label:after,
    //.ui.checked.slider.checkbox label:after,
    //.ui.checked.toggle.checkbox label:after {
    //  //background: @InputBackgroundColorLight;
    //  //border-radius: 10px;
    //  //box-shadow: 0 2px 6px @BackGroundWithOpacity;
    //}
    //
    //.ui.slider.checkbox label:before,
    //.ui.toggle.checkbox label:before,
    //.ui.checked.slider.checkbox label:before,
    //.ui.checked.toggle.checkbox label:before {
    //  width: 40px;
    //  height: 9px;
    //  border-radius: 5px;
    //  //background-color: @CheckboxBorderColor !important;
    //  top: 2px;
    //}
    //
    //.ui.toggle.checkbox label:before,
    //.ui.checked.toggle.checkbox label:before {
    //  top: 6px;
    //}
    //
    //.ui.slider.checkbox.checked input:focus ~ label:before,
    //.ui.toggle.checkbox.checked input:focus ~ label:before,
    //.ui.slider.checkbox input:checked ~ label:before,
    //.ui.checked.toggle.checkbox label:before {
    //  //background-color: @redLight !important;
    //  border: none;
    //}
    //
    //.ui.slider.checkbox label, .ui.toggle.checkbox label {
    //  padding-left: 55px;
    //  //color: @TextColorLight;
    //}
}