@import '../../semantic-ui/variables.less';

.simple-table {
    max-height: 612px;
    min-height: 100px;
    position: relative;
    height: fit-content;
    overflow-y: auto;

    td {
        vertical-align: middle;
    }

    &__no-data {
        text-align: center !important;
        padding-top: 40px !important;
        font-style: italic;
    }

    &__table {
        &-row {
            border-bottom: 1px solid @border-color !important;

            &_success > td {
                background-color: fadeout(@color-option-1, 93%)  !important;
                color: @color-option-1;
            }

            &_danger > td {
                background-color: fadeout(@color-option-3, 93%) !important;
                color: @color-option-3 !important;
            }
        }

        &-cell {
            padding: 14px 32px !important;
            font-size: 13px;
            color: @dark-80;
            vertical-align: top;

            &:first-child {
                font-weight: @label-bold-weight;
            }
        }
    }
}
