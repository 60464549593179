.main-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 257px;
    padding-left: 10px;
    padding-right: 10px;
}

@media screen and (max-width: 1630px) {
    .main-content {
        margin-left: 200px;
        width: calc(100% - 200px);
    }
}

@media screen and (max-width: 1280px) {
    .main-content {
        margin-left: 50px;
        width: calc(100% - 50px);
        min-width: 992px;
        padding-left: 0;
        padding-right: 0;
    }
}