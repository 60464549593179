@import '../../semantic-ui/variables.less';

.custom-modal-cell {
	&__company,
	&__author {
		display: flex;
		flex-direction: column;
		cursor: pointer;
	}

	&__author {
		cursor: default;
	}

	&__temperature {
		display: grid;
		grid-template-columns: calc(100% - 100px) 200px;
		&-content {
			display: grid;
			font-size: 12px;
		}
	}

	&__link:hover {
		color: @primaryColor !important;
	}
	&__contact {
		&-phone,
		&-email {
			padding-top: 3px;
			font-size: 11px;
		}
	}
}
