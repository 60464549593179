@import '../../semantic-ui//variables.less';

.notifications {
	position: fixed;
	bottom: 23px;
	right: 10px;
	min-width: 350px;
	min-height: 88px;
	z-index: 9999;

	&_hidden {
		display: none;
	}

	&__list {
		display: flex;
		width: 100%;
		flex-direction: column;
		margin: 0;
		list-style: none;
		padding: 0;
	}
}
