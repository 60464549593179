@import '../../../semantic-ui/variables.less';

.login-field {
	width: 100%;
	position: relative;

	& input {
		width: 100%;
		height: 50px;
		font-size: 13px;
		font-weight: 500;
		padding: 14px 11px !important;
		border-color: @dark-20 !important;
		border-radius: 10px !important;
		background-color: @white-500 !important;

		&:focus {
			border-color: @gray-500 !important;
			padding-top: 23px !important;
			padding-bottom: 9px !important;
		}
	}

	&_focused input {
		border-color: @gray-500 !important;
		padding-top: 23px !important;
		padding-bottom: 9px !important;
	}

	&__label {
		position: absolute;
		top: 14px;
		left: 12px;
		z-index: 2;
		color: @gray-90;
		font-size: 13px;
		font-weight: 400;
		transform-origin: 0 0;
		transition: transform 0.1s ease-in-out;

		&_focused {
			transform: scale(0.85) translateY(-0.5rem) translate(0.15rem);
		}
	}

	&_error {
		& .ui.input input {
			border: 1px solid @color-option-3 !important;
			color: @color-option-3 !important;
		}

		& .icon {
			color: @color-option-3 !important;
		}

		& .login-field__label {
			color: @color-option-3;
		}
	}
}
