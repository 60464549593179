@import '../../../semantic-ui/variables.less';

.validation_error {
	color: @color-option-3;
	font-size: 11px;
	font-weight: 600;
	padding-top: 3px;
}

.input_row {
	& input {
		font-size: 13px;
		font-weight: 500;
		padding: 6px 12px !important;
		border: 1px solid rgba(34, 36, 38, 0.15);
		color: rgba(0, 0, 0, 0.67) !important;
		cursor: text;

		&:focus {
			border-color: @gray-500 !important;
		}

		&:hover	{
			border-color: @gray-500 !important;
		}

		&::placeholder {
			color: rgba(0, 0, 0, 0.25) !important;
			font-weight: 400 !important;
		}
	}

	& input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	& input[type='number'] {
		-moz-appearance: textfield;
	}

	&.error .input-group-text {
		border-top-color: transparent;
		border-bottom-color: transparent;
		border-right-color: transparent;
	}

	&.error .ui.input input {
		border: 1px solid @color-option-3 !important;
	}

	&__popup-text {
		text-align: center !important;
	}

	&.success .ui.input input {
		color: @color-option-1;
		border-color: @color-option-1 !important;
	}

	&.danger .ui.input input {
		color: @color-option-3;
		border-color: @color-option-3 !important;
	}

	&.input_row_text_center .ui.input input {
		text-align: center;
	}

	&.input_row_text_right .ui.input input {
		text-align: right;
	}

	&.input_row_text_left .ui.input input {
		text-align: left;
	}
}
