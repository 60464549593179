@import 'semantic-ui/variables.less';

body {
    font-weight: 500;
}

a {
    color: @dark-80 !important;
    text-decoration: underline !important;
}

.ui.dimmer {
    background-color: @modalBackground !important;
}

.ui.loader:after{
    border-color: @primary-500 transparent transparent !important;
}

.ui.inverted.popup {
    background: @primary-500;
}

.ui.inverted.top.popup:before, .ui.bottom.center.popup:before {
    background: @primary-500;
}

.ui.inverted.left.popup:before, .ui.left.center.popup:before {
    background: @primary-500!important;
}

