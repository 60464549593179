@import "../../../../semantic-ui/variables.less";

.req-analytics-component {
    &__row {
        padding: 0 0 12px 0 !important;

        & .select_row .ui.selection.dropdown .menu{
            max-width: 221px;
        }

        &-label {
            padding: 10px 0 50px 0;
            font-size: 13px;
            font-weight: 400;
            text-align: center;
            width: 100%;
            display: block;
        }

        &-button-wrapper {
            display: flex;
            justify-content: center;
            padding-bottom: 20px;
        }
    }
    &__row:nth-child(2) {
        position: absolute !important;
        top: 170px;
    }
}