@import '../../../../semantic-ui/variables.less';

.custom-modal-cell {
	&__author {
		display: flex;
		flex-direction: column;
		cursor: default;
	}

	&__link:hover {
		color: @primaryColor !important;
	}
}
