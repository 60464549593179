body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Montserrat", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.root {
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

*::-webkit-scrollbar {
  width: 6px !important;
}

*::-webkit-scrollbar-track {
  background-color: rgba(160, 169, 166, 0.1) !important;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(160, 169, 166, 0.3) !important;
  border: 1px solid rgba(160, 169, 166, 0.1) !important;
  border-radius: 12px !important;
}