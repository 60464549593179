@import '../../semantic-ui/variables.less';

.badge {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: @dark-500;
    min-width: 108px;
    padding: 10px;
    border-radius: 4px;
    background-color: @gray-10;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;

    &_small {
        min-width: 70px;
        margin-left: 5px;
    }
}

@media screen and (max-width: 1630px) {
    .badge {
        min-width: 96px;
        padding: 10px 5px;
        &_small {
            min-width: 50px;
            padding: 10px 2px;
        }
    }
}