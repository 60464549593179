@import '../../semantic-ui/variables.less';

.status-indicator {
	width: 9px;
	height: 9px;
	border-radius: 50%;
	cursor: pointer;

	&_success {
		background-color: @color-option-1;
	}

	&_warning {
		background-color: @secondary-600;
	}

	&_danger {
		background-color: @color-option-3;
	}
}
