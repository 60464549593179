@import '../../variables.less';

.custom-datepicker {
	width: 100%;
	position: static;
	font-family: @body-1-regular-family;

	.react-datepicker-popper {
		z-index: 2;
	}

	.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
		border-bottom-color: #fff;
	}

	.react-datepicker__day-name {
		width: 30px;
		color: #a0a9a6;
		font-size: 14px;
		font-weight: 500;
		font-family: @body-1-regular-family;
		margin: 0;
	}

	.react-datepicker__month-text {
		font-size: 14px;
		font-weight: 400 !important;
		padding: 5px;
		font-family: @body-1-regular-family;
	}

	.custom-datepicker__day {
		font-size: 14px;
		font-weight: 400 !important;
		padding: 5px;
		font-family: @body-1-regular-family;
	}

	.react-datepicker__day--today, .react-datepicker__month-text--today {
		font-weight: 700 !important;
	}

	.react-datepicker__header {
		background: @white-500;
		border: none;
		padding: 4px 0;
	}

	.react-datepicker-wrapper {
		width: 100%;
	}

	.react-datepicker__month-text--keyboard-selected {
		color: @white-500;
		background-color: @primary-500;
		border: none;
		outline: none;

		&:hover {
			background: @primary-500;
		}
	}

	.react-datepicker__day--keyboard-selected {
		background-color: @white-500;
		border: none;
		outline: none;

		&:hover {
			background: #eee;
		}
	}

	.react-datepicker__day--today {
		font-weight: bolder;
	}

	&__input {
		margin: 0;
		height: 31px;
		width: 100%;
		max-width: 100%;
		flex: 1 0 auto;
		outline: none;
		-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
		text-align: left;
		line-height: 1.21428571em;
		font-family: 'Montserrat', sans-serif;
		padding: 5px 12px;
		background: @white-500;
		border: 1px solid rgba(34, 36, 38, 0.15);
		color: rgba(0, 0, 0, 0.67);
		border-radius: 0.28571429rem;
		transition: box-shadow 0.1s ease, border-color 0.1s ease;
		box-shadow: none;
		font-weight: 500;

		&:focus {
			border-color: @gray-500 !important;
		}

		&:hover	{
			border-color: @gray-500 !important;
		}

		&::placeholder {
			color: rgba(0, 0, 0, 0.25) !important;
			font-weight: 400;
		}

		&_error {
			border: 1px solid @color-option-3;
		}

		&_text_right {
			text-align: right;
		}

		&_text_left {
			text-align: left;
		}

		&_text_center {
			text-align: center;
		}
	}

	&__calendar {
		padding: 17px;
		border-radius: 12px;
		border: 1px solid rgba(16, 21, 19, 0.05);
		background: @white-500;
		box-shadow: 0 17px 48px #00000029;
	}

	&__day {
		width: 30px;
		height: 30px;
		padding-top: 3px;
		font-size: 14px;
		font-weight: 500;
		border-radius: 8px;
		vertical-align: middle;
		font-family: @body-1-regular-family;
		margin: 0;

		&:hover {
			border-radius: 8px;
			border: none;
		}

		&_gray {
			color: #a0a9a6;
		}

		&_active {
			background: @primary-500;

			&:hover {
				background: @primary-500;
			}
		}
	}

	&__error-text {
		color: @color-option-3;
		font-size: 11px;
		font-weight: 600;
	}
}
