@import '../../../variables.less';

.custom-datepicker-header {
	height: 30px;
	display: flex;
	justify-content: center;
	background: @white-500;

	&__current-month {
		font-size: 15px;
		font-weight: 400;
		font-family: @body-1-regular-family;
		padding-top: 3px;
		cursor: default;
	}

	&__button {
		width: 30px;
		height: 30px;
		display: flex;
		padding-top: 10px;
		padding-left: 10px;
		position: absolute;
		left: 5px;
		cursor: pointer;
		font-size: 8px;
		border-radius: 8px;
		line-height: 12px;

		&:last-child {
			right: 5px;
			left: auto;
		}

		&:hover {
			background: #eee;
		}
	}
}
