@import '../../semantic-ui/variables.less';

.status {
    font-family: @header-7-bold-family, sans-serif !important;
    padding: 10px 0;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    white-space: normal !important;
    min-width: 140px;
    width: fit-content;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 80%;

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        position: relative;
        padding: 0 10px;
    }

    &_default {
        background-color: @gray-10;
        color: @dark-80;
    }

    &_secondary {
        background-color: @gray-10;
        color: @dark-80;
    }

    &_success {
        background-color: rgba(48, 190, 130, 0.1);
        color: @color-option-1;
    }

    &_warning {
        background-color: rgba(207, 44, 44, 0.1);
        color: @color-option-3;
    }

    &_danger {
        background-color: rgba(207, 44, 44, 0.1);
        color: @color-option-3;
    }

    &_primary {
        background-color: #ff630012;
        color: #ff6300;
    }

    &_info {
        background-color: rgba(0, 97, 173, 0.1);
        color: @primary-600;
    }

    &_nowrap {
        white-space: nowrap !important;
    }

    &__line {
      white-space: pre-line;
    }

    &__divider {
        border: none;
        height: 1px;
        margin: 5px 0;
        width: 100%;

        &_default {
            background-color: @dark-80;
            opacity: 0.2;
        }

        &_secondary {
            background-color: @dark-80;
            opacity: 0.2;
        }

        &_success {
            background-color: @color-option-1;
            opacity: 0.2;
        }

        &_warning {
            background-color: @color-option-3;
            opacity: 0.2;
        }

        &_danger {
            background-color: @color-option-3;
            opacity: 0.2;
        }

        &_primary {
            background-color: #ff6300;
            opacity: 0.2;
        }
    }

    &_fullwidth {
        width: 100% !important;
    }

    &_with-max-height {
        max-height: 50px;
    };

    &__status-details {
        display: block;
        position: absolute;
        right: -7px;
        top: -7px;
        max-width: 30%;
    }

    &__status-details .info-icon {
        opacity: 1;
        width: 20px;
    }

    &__status-details .info-icon:hover {
        opacity: 1;
        width: 20px;
    }
}