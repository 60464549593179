@import '../../../../semantic-ui/variables.less';

.req-wrapper {
	display: flex;
	align-items: flex-start;
	&__header {
		height: 56px;
		display: flex;
		justify-content: space-between;
		padding-bottom: 15px;
		padding-right: calc(33% + 20px);

		&_with-one-item {
			justify-content: flex-end;
		}
		&-left {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 10px;
		}
	}
	&__filter {
		margin-left: 20px;
		flex: 1;
		width: 33%;
		position: sticky;
		top: 20px;
		bottom: 20px;
	}
	&__reqs {
		flex: 5;
		&-items {
			display: flex;
		}
		&-item {
			min-height: 268px;
			flex: 12;
			margin-bottom: 10px;
		}
		&-item-with-labels {
			height: fit-content;
			flex: 12;
		}
		&-status {
			min-height: 268px;
			flex: 4;
			min-width: 204px;
			margin-bottom: 10px;
		}
	}
	&__button {
		height: 37px;
		background: white;
		border-radius: 12px;
		border: 1px solid @border-color;
		padding: 8px 20px;
		font-size: 13px;
		font-weight: 500;
		color: #101513;
		cursor: pointer;
		font-family: 'Montserrat';
		transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, background-image 0.15s ease-in-out,
			border-color 0.15s ease-in-out;

		&:hover {
			background-color: @border-color;
			border-color: @border-color;
		}
	}

	&__empty-list {
		text-align: center;
		padding: 15px;
		border-radius: 12px;
		border: 1px solid @border-color;
		height: 100px;
		display: flex;
		justify-content: center;
		flex-direction: column;
		background-color: white;
		&-text {
			width: 100%;
			background-color: #1015130d;
			height: 50px;
			text-align: center;
			vertical-align: middle;
			padding-top: 15px;
			border-radius: 5px;
			color: #101513cc;
		}
	}
	&__actions {
		padding: 5px 10px;
		display: flex;
		height: 37px;
		background-color: @white-500;
		border-radius: 12px;
		border: 1px solid @border-color;
	}
}

@media screen and (max-width: 1630px) {
	.req-wrapper {
		&__filter {
			width: 27%;
		}

		&__header {
			padding-right: calc(27% + 20px);
		}
	}
}

@media screen and (max-width: 1280px) {
	.req-wrapper {
		&__filter {
			margin-left: 10px;
		}

		&__header {
			padding-right: calc(25% + 10px);
		}
	}
}

.select-all-button {
	width: 24px;
	margin-right: 2px;
	height: 24px;
	cursor: pointer;
	background-image: url('../../../../components/images/select-all-button.svg');
	transition: background-image .15s ease-in-out;
	background-repeat: no-repeat;
	border-radius: 0.5rem;

	&:hover {
		background-color: rgba(160, 169, 166, 0.3);
	}
}
.deselect-all-button {
	width: 24px;
	height: 24px;
	cursor: pointer;
	background-image: url('../../../../components/images/deselect-button.svg');
	transition: background-image .15s ease-in-out;
	background-repeat: no-repeat;
	border-radius: 0.5rem;

	&:hover {
		background-color: rgba(160, 169, 166, 0.3);
	}
}
.download-button {
	width: 24px;
	height: 24px;
	margin: 1px;
	cursor: pointer;
	background-image: url('../../../../components/images/download-button.svg');
	transition: background-image .15s ease-in-out;
	background-repeat: no-repeat;

	&:hover {
		background-image: url('../../../../components/images/download-button-hover.svg');
	}
}
