@import '../../../../../semantic-ui/variables.less';

.reqs-list-timer {
	width: 252px;
	height: 41px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 12px;
	background: @dark-5;
	font-size: 12px;
}
