@import '../../../../semantic-ui/variables.less';

.reqs-filter {
    position: sticky;
	background-color: white;
    border: 1px solid @border-color;
    text-align: left;
    display: flex;
    flex-direction: column;
    margin: auto;
    vertical-align: middle;
    justify-content: center;
    padding: 5px;
}
