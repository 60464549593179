@import '../../../semantic-ui/variables.less';

.company-accredited {
	display: flex;
	padding: 50px;
	gap: 40px;

	&__content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 25px;
	}

	&__title {
		font-family: @body-1-regular-family;
		font-weight: 700;
		font-size: 28px;
		line-height: 35px;

		&_primary {
			color: @primary-500;
		}

		&_secondary {
			color: @secondary-500;
		}
	}

	&__text {
		font-family: @body-1-regular-family;
		font-weight: 400;
		font-size: 15px;
		line-height: 25px;
		max-width: 600px;
	}

	&__overlay {
		position: absolute;
		top: 100px;
		bottom: 0;
		left: 0;
		right: 0;
	}
}
