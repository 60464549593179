@import '../../../../../../semantic-ui/variables.less';

.way-form {
	margin: 0 !important;
	padding: 20px 0 !important;
	max-height: 511px;
	overflow-y: auto;

	&__row {
		margin: 0 !important;
		padding-top: 0 !important;
		padding-bottom: 14px !important;

		&:last-child {
			padding-bottom: 0 !important;
		}
	}

	&__column {
		position: relative;
		font-size: 13px;
		font-weight: @label-regular-weight;
		line-height: 1.5;
		flex-wrap: wrap;

		&:first-child {
			display: flex !important;
			justify-content: flex-end;
			align-items: center;
		}

		&-with-button {
			display: inline-flex;
			width: 100%;

			& img {
				padding-left: 10px;
				cursor: pointer;
			}
		}
		&_close {
			display: flex;
			justify-content: flex-end;
			position: relative;
			top: -20px;
			right: -45px;
			margin-left: auto;

			img {
				cursor: pointer;
				width: 24.5px;
				height: 24.5px;
			}
		}
	}

	&__button-row {
		display: flex;
		justify-content: flex-end;
		margin: auto;
		width: 560px;
		margin-bottom: 20px;
		margin-top: 5px;

		&-text {
			margin-top: 2px;
		}

		img {
			cursor: pointer;
			margin-left: 10px;
		}
	}

	&__field-with-button {
		width: 100%;
	}

	&__modes {
		display: flex;
		flex-wrap: wrap;
	}

	&__mode {
		display: flex;
		margin-right: 10px;
		min-width: 90px;
	}

	&__mode-error {
		display: block;
		color: @color-option-3;
		font-size: 11px;
		font-weight: 600;
		padding-top: 3px;
	}

	&__mode-text {
		margin-left: 5px;
		margin-right: 5px;
	}

	&__column_close {
		width: 24px;
		height: 24px;
		background: url('../../../../../../components/images/close.svg') no-repeat;
		background-size: 100%;
		cursor: pointer;

		&:hover {
			width: 24px;
			height: 24px;
			background: url('../../../../../../components/images/close-hover.svg') no-repeat;
			background-size: 100%;
		}
	}
}
