@import '../../../../../semantic-ui/variables.less';

.claim-form {
    margin: 0 !important;
    padding: 20px 0 !important;

    &__loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1000;
    }

    &__row {
        margin: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 14px !important;

        &:last-child {
            padding-bottom: 0 !important;
        }
    }

    &__column {
        font-size: 13px;
        font-weight: @label-regular-weight;
        line-height: 1.5;

        &:first-child {
            display: flex !important;
            justify-content: flex-end;
            align-items: center;
        }
    }
} 