@import "../../../../../../semantic-ui/variables.less";

.city-item {
    font-family: @header-7-bold-family, sans-serif !important;

    &__sub {
        border-top: 1px solid @border-color !important;
        padding: 10px 0 13px 30px;
    }

    &__header {
        display: flex;
        gap: 20px;
        font-weight: 400;
        line-height: 1.5;
        padding-top: 5px;
    }

    &__info {
        font-size: 10px;
        font-weight: 400;
        padding-top: 5px;

        & span {
            padding: 3px 6px;
            border-radius: 3px;
            background: @gray-10;
            font-weight: 600;
            margin: 0 6px;

            &:first-child {
                margin-left: 0 !important;
            }
        }
    }
}