@import '../../../../../semantic-ui/variables.less';

.req-status-actions {
	height: 100%;
	background-color: @white-500;
	border: 1px solid @border-color;
	border-radius: 0 12px 12px 0;
	text-align: left;
	display: flex;
	flex-direction: column;
	margin: auto;
	vertical-align: middle;
	justify-content: center;
	font-size: 12px;

	&__statuses {
		padding: 0 15px;
	}

	&__actions {
		display: flex;
		flex-direction: column;
		gap: 10px;
		border-top: 1px solid @border-color;
		margin-top: 20px;
		padding: 20px 15px 0 15px;
	}

	&__link:has(.status_success) {
		text-decoration-color: @color-option-1 !important;
	}

	&__link:has(.status_default) {
		text-decoration-color: @dark-80 !important;
	}

	&__link:has(.status_secondary) {
		text-decoration-color: @dark-80 !important;
	}

	&__link:has(.status_warning) {
		text-decoration-color: @color-option-3 !important;
	}

	&__link:has(.status_danger) {
		text-decoration-color: @color-option-3 !important;
	}

	&__link:has(.status_info) {
		text-decoration-color: @primary-800 !important;
	}

	&__link:has(.status_primary) {
		text-decoration-color: #ff6300 !important;
	}

	&__button {
		position: relative;

		&-badges {
			position: absolute;
			right: 1px;
			top: -6px;
			border-radius: 4px;
			font-size: 9px;
			font-weight: 700;
			color: @white-500;
			padding: 3px 6px;
			line-height: 11px;

			&_primary {
				background-color: @secondary-500;
			}

			&_secondary {
				background-color: @gray-500;
			}
		}
	}
}
