@import '../../variables.less';

.modal-window {
	margin-top: 0 !important;
	border-radius: 12px !important;
	box-shadow: @shadow_modal !important;

	&__header {
		display: flex !important;
		justify-content: space-between;
		align-items: center;
		height: 56px !important;
		border-radius: 12px 12px 0 0 !important;
		padding: 0 32px !important;
		border: 1px solid @border-color;

		&_big {
			height: 81px !important;
		}

		&-block {
			display: flex;
			gap: 15px;
		}

		&-text {
			font-family: @header-7-bold-family, sans-serif !important;

			font-weight: 500;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			padding-right: 5px;

			&-title {
				font-size: @header-7-bold-size;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			&-regular {
				padding-top: 10px;
				overflow: hidden;
				text-overflow: ellipsis;
				font-size: @caption-bold-size;
			}

			span.divide {
				border-bottom: 3px solid @secondary-500;
			}
		}

		&-note {
			&-icon {
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
			}

			&-image {
				width: 24px;
				height: 24px;
			}
		}
		&-actions {
			display: flex;
			gap: 20px;
			align-items: center;
		}

		&-statuses {
			display: flex;
			gap: 15px;
		}
	}

	&.ui.modal {
		top: calc(50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}

	&.ui.modal:has(.modal-window__nav) {
		top: calc(50% + 23px);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}

	&.ui.modal > .content {
		border-bottom: 1px solid rgba(34, 36, 38, 0.15);
		padding: 0;
		overflow: initial;
		max-width: 900px;
		border-radius: 12px !important;
	}

	&.ui.modal > .header + .content {
		border-top-left-radius: 0 !important;
		border-top-right-radius: 0 !important;
	}

	&__nav {
		position: absolute;
		top: -43px;
		left: 50px;
		display: flex;

		&-item {
			padding: 10px 13px;
			cursor: pointer;
			color: @dark-80;
			font-size: 14px;
			border: 1px solid @border-color;
			border-bottom: none;
			border-radius: 4px 4px 0 0;
			line-height: 1.7;
			font-weight: 600;
			background-color: #f8f9fa;

			&_active {
				background-color: @white-500;
			}
		}
	}

	&.ui.modal &__content {
		&_with-buttons {
			border-radius: 0 !important;
		}
	}

	&.ui.modal &__content {
		&_with-buttons > div {
			max-height: calc(100vh - 160px);
		}
	}

	&.ui.modal:has(.modal-window__nav) &__content {
		&_with-buttons > div {
			max-height: calc(100vh - 200px);
		}
	}

	&.ui.modal .content > div:not(.not-overflow) {
		max-height: calc(100vh - 160px);
		overflow: hidden auto;
	}

	&.ui.modal:has(.modal-window__nav) .content > div:not(.not-overflow) {
		max-height: calc(100vh - 200px);
		overflow: hidden auto;
	}
}

.dimmed.dimmable > #root > :not(.notifications) {
	-webkit-filter: blur(5px) grayscale(0.7);
	filter: blur(5px) grayscale(0.7);
}

.ui.grid > .row,
.ui.grid > .row > .column {
	position: initial !important;
}

@media (max-width: 1000px) {
	.modal-window {
		.ui[class*='three column'].grid > .row > .column,
		.ui.grid > .row > .column {
			width: 100%;
		}

		&__header-text {
			font-size: 14px;
		}
	}
}

@media (max-width: 780px) {
	.modal-window__header {
		display: block !important;
	}
}
