@import '../../variables.less';

.validation_error {
    color: @color-option-3;
    font-size: 11px;
    font-weight: 600;
    padding-top: 3px;
}

.select_row {
    font-size: 13px;
    font-weight: 500;

    & .lf {
        font-size: 11px;
        color: rgba(0, 0, 0, 0.25);
    }

    & input::-webkit-contacts-auto-fill-button {
        visibility: hidden;
        display: none !important;
        pointer-events: none;
        position: absolute;
        right: 0;
        user-select: none !important;
    }

    & .ui.selection.dropdown, & .ui.search.selection.dropdown > input.search {
        min-height: 30px !important;
        padding: 6px 15px !important;
        line-height: 1.15 !important;
        font-family: @header-7-bold-family, sans-serif !important;
    }

    & .ui.selection.dropdown, & .ui.search.selection.dropdown > input:-webkit-contacts-auto-fill-button {
        min-height: 30px !important;
        padding: 6px 15px !important;
        line-height: 1.15 !important;
        font-family: @header-7-bold-family, sans-serif !important;
    }

    &.error .ui.selection.dropdown {
        border: 1px solid @color-option-3 !important;
    }

    & .ui.selection.dropdown {
        transform: none;
        padding: 0 !important;;
    }

    & .ui.selection.dropdown:focus, & .ui.selection.dropdown:hover {
        border-color: @gray-500 !important;
        outline: none;
        transition: background-color .3s;
    }

    & .ui.selection.dropdown.static {
        position: static !important;
    }

    .ui.selection.active.dropdown {
        border-color: rgba(34, 36, 38, 0.15) !important;
        border-radius: 0.3rem !important;
        box-shadow: none;
    }

    .ui.selection.active.dropdown:hover {
        box-shadow: none;
    }

    & .ui.search.selection.dropdown > .search {
        position: relative !important;
        top: auto;
        left: auto;
        right: auto;
        padding: 0;
        margin: 0 !important;
        min-height: 30px;
        border: none;
        width: 100% !important;
        z-index: 1;
    }

    & .ui.search.selection.dropdown > .search > input {
        position: relative !important;
        top: auto;
        left: auto;
        right: auto;
        padding: 0 0 0 12px;
        margin: 0 !important;
        border: none;
        width: 100%;
    }

    & .ui.search.selection.dropdown > .search > input::placeholder {
        color: rgba(0, 0, 0, 0.25);
    }

    & .ui.search.selection.dropdown > .search.selected > input::placeholder {
        color: rgba(0, 0, 0, 0.67);
        font-size: 13px;
        font-weight: 500;
    }

    & .ui.search.selection.dropdown > .search.icon {
        opacity: 1 !important;
    }

    & .ui.search.selection.dropdown > .search > i.icon {
        opacity: 0.2 !important;
    }

    & .ui.selection.dropdown:has(.search) > .text {
        display: none;
    }

    & .ui.selection.dropdown > .text {
        display: block;
        width: 100%;
        height: 28px;
        line-height: 28px;
        padding-right: 2.67142857em !important;
        padding-left: 12px !important;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.67);
    }

    & .ui.selection.dropdown > .text.default {
        color: rgba(0, 0, 0, 0.25);
        font-weight: 400 !important;
    }

    &__icon {
        position: absolute;
        top: 8px;
        right: 12px;
        cursor: pointer;
    }

    & .ui.selection.dropdown .menu > .item {
        font-size: 13px;
        line-height: 20px;
        border: none;
    }

    & .ui.selection.dropdown .menu > .selected.item {
        background: none !important;
    }

    & .ui.selection.dropdown .menu > .selected.item.active, & .ui.selection.dropdown .menu > .selected.item:hover {
        background: @dark-5 !important;
    }

    & .ui.selection.dropdown .menu {
        top: auto;
        bottom: auto;
        left: auto;
        right: auto;
        margin: 2px auto;
        width: 100%;
        min-width: 221px;
        max-width: 321px;
        border-radius: 0.3rem;
        max-height: 221px;
        border: 1px solid rgba(34, 36, 38, 0.15) !important;
    }

    & .ui.selection.dropdown .menu > .item:first-child {
        border-radius: 0.3rem 0.3rem 0 0;
    }

    & .ui.selection.dropdown .menu > .item:last-child {
        border-radius: 0 0 0.3rem 0.3rem;
    }

    & .ui.fluid.multiple.search.selection.dropdown {
        padding: 0 !important;

        & > input.search {
            margin: 0 !important;
        }

        & .divider.default.text {
            margin: 7px 0 0 16px;
        }

        & .ui.label {
            margin: 3px 5px !important;
            background-color: transparent !important;
            font-weight: 500 !important;
            text-decoration: none !important;
            padding: 7px 13px !important;
        }
    }
}
