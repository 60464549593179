@import '../../../../../semantic-ui/variables.less';

.address-form {
	display: flex;
	flex-direction: column;
	padding: 24px 14px;
	overflow: initial;

	& .form-select {
		&__menu {
			max-height: 150px;
		}
	}

	&__section {
		padding: 24px 14px;
	}

	&__row {
		display: flex;
		align-items: flex-start;
		margin-bottom: 14px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__text {
		width: 40%;
		max-width: 292px;
		font-family: @body-1-regular-family;
		font-size: 13px;
		font-weight: 400;
		padding: 6px 10px;

		&_right {
			text-align: right;
		}

		&_bold {
			font-weight: bolder;
		}
	}

	&__field {
		width: 60%;
		max-width: 437px;
		padding: 0 10px;
	}
}
