.reqs-custom-modal-sub-value-cell {
	&__cell {
		&_bold {
			font-size: 12px;
			font-weight: 600;
		}

		&_thin {
			font-size: 12px;
			font-weight: 400;
		}
	}
	&__driver {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}
}
