@import '../../semantic-ui/variables.less';

.status-button {
    border: 1px solid;
    border-radius: 12px !important;
    font-size: 13px;
    padding: 8px 20px;
    color: @dark-500 !important;
    line-height: 1.5;
    cursor: pointer;
    font-weight: 500;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, background-image .15s ease-in-out, border-color .15s ease-in-out;
    text-align: center;

    &_success {
        border-color: @color-option-1;
        &:hover {
            color: @white-500 !important;
            background-color: @color-option-1;
        }
    }

    &_default {
        border-color: @gray-500;
        &:hover {
            color: @white-500 !important;
            background-color: @gray-500;
        }
    }

    &_warning {
        border-color: @color-option-3;
        &:hover {
            color: @white-500 !important;
            background-color: @color-option-3;
        }
    }
}