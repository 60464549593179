@import '../../../../semantic-ui/variables.less';

.reqs-claims-modal-cell {
	&__link {
        padding-top: 20px;
		color: @primaryColor;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	&__uuid {
		padding-top: 20px;
		min-height: 60px;
	}

	&__contact {
		display: flex;
		flex-direction: column;
		gap: 4px;

		&-name {
			font-weight: 600;
		}

		&-phone {
			color: @dark-80;
		}
	}
} 