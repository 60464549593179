@import '../../semantic-ui/variables.less';


.timeline {
  background-color: transparent;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.2;
}

.timeline__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 32px;
}

.timeline__side {
  text-align: left;
  color: white;
  width: 130px;
  height: 32px;
  padding-top: 3px;

  &--left {
    text-align: right;
    padding-right: 10px;
  }

  &--right {
    padding-left: 10px;
  }

  &--gray {
    color: @gray-500;
  }

  &--primary {
    color: @secondary-500;
  }

  &--current {
    font-weight: bold;
  }
}

.timeline__marker {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  
}

.timeline__circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  top: 5px;

  &--primary {
    background-color: @secondary-500;
  }
}

.timeline__line {
  width: 1px;
  height: 32px;
  background-color: white;
}
