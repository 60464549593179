@import "../../variables.less";

.form-double-phone-field {
    display: flex !important;

    & .input_row:first-child {
        width: 60%;

        & input {
            border-radius: 4px 0 0 4px !important;
        }
    }

    & .input_row:last-child {
        width: 40%;

        & input {
            border-radius: 0 4px 4px 0 !important;
            border-left: 0px !important;

            &:focus {
                border-left: 1px solid @gray-500 !important;
            }
        }
    }
}