@import '../../../../../semantic-ui/variables.less';

.cargo-delivery-form {
	display: flex;
	flex-direction: column;
	min-height: 167px;

	&__header {
		padding: 0 32px;
		min-height: 56px;
		border-bottom: 1px solid @border-color;
		display: flex;
		align-items: center;
		gap: 20px;
	}

	&__header-text {
		font-family: @header-7-regular-family;
		font-size: 16px;
		font-weight: 400;
		color: @dark-500;

		&_bold {
			font-weight: 600;
		}

		&_min-weight {
			min-width: 260px;
		}
	}

	&__route-list {
		display: flex;
		flex-direction: column;
		gap: 11px;
		padding: 17px 32px 34px;
		list-style: none;
	}

	&__route-item {
		border: 1px solid @border-color;
		border-radius: 12px;
		box-shadow: 0px 8px 16px 0px @dark-5;
		min-height: 137px;
	}

	&__route-row {
		padding: 0 17px 0 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		&_top {
			border-bottom: 1px solid @border-color;
			min-height: 56px;
		}

		&_bottom {
			margin-top: 21px;
			margin-bottom: 21px;
		}
	}

	&__header-route-row {
		flex-direction: column;
	}

	&__inner-route-row {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__route-title {
		max-width: 530px;
		font-family: @header-7-regular-family;
		font-size: 11px;
		font-weight: 400;
		color: @dark-500;
		line-height: 13px;

		&_small {
			font-size: 10px;
			line-height: 12px;
			color: @dark-80;
		}
	}

	&__date-section {
		padding: 5px 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 4px;
	}

	&__date-field {
		width: 85px;
		height: 31px;
		font-size: 11px;
		font-weight: 500;
	}

	&__route-info-field {
		height: 21px;
		display: flex;
		gap: 15px;
	}

	&__route-info-item {
		display: flex;
		align-items: center;
		gap: 4px;
	}

	&__button {
		border: 1px solid @color-option-1;
		border-radius: 12px !important;
		font-size: 13px;
		padding: 8px 20px;
		color: @dark-500 !important;
		line-height: 1.5;
		cursor: pointer;
		font-weight: 500;
		transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, background-image 0.15s ease-in-out,
			border-color 0.15s ease-in-out;
		width: fit-content;

		&:hover {
			color: @white-500 !important;
			background-color: @color-option-1;
		}
	}

	&__error-text {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		color: @color-option-3;
		font-size: 11px;
		font-weight: 600;
	}

	&__no-data-message {
		width: 100%;
		height: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
