@import '../../../../../semantic-ui/variables.less';

.cargo-pickup-form {
	max-height: 709px;
	display: flex;
	flex-direction: column;
	gap: 15px;

	&__header {
		padding: 0 32px;
		min-height: 56px;
		border-bottom: 1px solid @border-color;
		display: flex;
		align-items: center;
		gap: 20px;
	}

	&__header-text {
		font-family: @header-7-regular-family;
		font-size: 16px;
		font-weight: 400;
		color: @dark-500;

		&_bold {
			font-family: @header-7-bold-family;
			font-weight: 600;
		}

		&_small {
			font-size: 14px;
		}

		&_min-weight {
			min-width: 250px;
		}
	}

	&__date-section {
		padding: 0 32px;
		display: flex;
		align-items: center;
		gap: 15px;
	}

	&__date-field {
		width: 120px;
	}

	&__date-text {
		font-family: @header-7-regular-family;
		font-size: 12px;
		font-weight: 400;
		color: @dark-500;
	}

	&__route-list {
		display: flex;
		flex-direction: column;
		gap: 11px;
		padding: 0 32px;
		list-style: none;
		overflow: auto;
	}

	&__route-item {
		border: 1px solid @border-color;
		border-radius: 12px;
		box-shadow: 0px 8px 16px 0px @dark-5;
		min-height: 137px;
	}

	&__route-item:last-child {
		margin-bottom: 21px;
	}

	&__route-header {
		border-bottom: 1px solid @border-color;
		min-height: 56px;
		padding: 0 20px;
		display: flex;
		align-items: center;
		gap: 14px;
	}

	&__checkbox-wrapper {
		margin-right: 5px;
	}

	&__route-title {
		font-family: @header-7-regular-family;
		font-size: 14px;
		font-weight: 400;
		color: @dark-500;

		&_small {
			font-size: 11px;
		}
	}

	&__route-info-field {
		height: 19px;
		display: flex;
		margin: 18px 20px 11px;
		gap: 15px;
	}

	&__route-info-item {
		display: flex;
		align-items: center;
		gap: 4px;
	}

	&__route-info-text {
		font-family: @header-7-regular-family;
		font-size: 10px;
		font-weight: 400;
		color: @dark-80;
	}

	&__route-info-special {
		padding: 0 20px;
	}
}
