@import '../../variables.less';

.file-uploader {
	display: inline-block;
	vertical-align: top;
	position: relative;

	&__wrapper {
		display: flex;
		align-items: center;
		gap: 10px;
	}

	&__file {
		width: 24px;
		height: 24px;
		position: relative;
		flex-shrink: 0;
	}

	.ui.input {
		position: absolute;
		bottom: 0;
		right: 0;
		z-index: 2;
		overflow: hidden;
		height: 24px;
		opacity: 0;
	}

	.ui.input input[type='file'] {
		padding: 0;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		position: relative;
		top: 0;
	}

	.ui.input > input {
		cursor: pointer;
	}

	&__icon-wrapper {
		position: absolute;
		bottom: 0;
		right: 0;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		cursor: pointer;
		background-image: url('../../../components/images/add-button-hover.svg');

		&_disabled {
			background-image: url('../../../components/images/add-button-disabled.svg');
		}
	}

	&__delete-icon-wrapper {
		position: absolute;
		top: 4px;
		right: -20px;
		width: 8px;
		height: 9px;
		cursor: pointer;
		background: url('../../../components/icons/remove-icon.svg');
	}

	&__files {
		display: flex;
		flex-wrap: wrap;
	}

	&__text {
		position: relative;
		user-select: none;
		margin-right: 31px;
		font-size: 12px;
	}
}
