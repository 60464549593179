@import '../../variables.less';

.table-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;

    &_full {
        padding-top: 10px !important;
        justify-content: space-between !important;
    }

    &__selector {
        display: flex;
        align-items: center;
        gap: 20px;

        &-field {
            & .ui.selection.dropdown {
                min-width: 70px !important;
                font-size: 13px;
            }

            & .ui.dropdown .menu .selected.item {
                background: @primary-500;
                color: @white-500;
                font-weight: 400;
            }

            & .ui.dropdown .menu > .item {
                font-size: 13px;
                padding: 10px !important;
            }
        }
    }

    &__prev-button, &__next-button {
        &_inactive {
            opacity: 0.5;
            cursor: default !important;
        }
    }

    &__text, &__selector-label {
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        color: @gray-80;
    }

    .ui.pagination.menu {
        margin: 13px 0;
        border: none;
        font-size: 11px;
        box-shadow: none;
        background: transparent;
    }

    .ui.pagination .item {
        background: @white-500;
        border: none;
        color: @dark-500;
        margin-right: 5px;
        text-decoration: none !important;
        border-radius: 8px;
        padding: 0 13px !important;
        display: flex;
        justify-content: center;
        min-width: 0px !important;
        font-weight: 600 !important;
    }

    .ui.menu > .item:first-child,
    .ui.menu > .item:last-child {
        border-radius: 8px !important;
        font-size: 30px;
        font-weight: 400 !important;
        line-height: 0 !important;
        background: transparent !important;
    }

    .ui.pagination .item:hover {
        background: @gray-20;
        color: @dark-500;
    }

    .ui.pagination.menu .active.item {
        color: @color-option-1;
        border: 1px solid @color-option-1;
        background: @white-500;
    }

    .ui.pagination .item:before {
        display: none;
    }
}