@import '../../../../../semantic-ui/variables.less';

.forgot-modal {
	padding: 30px;
	display: flex;
	flex-direction: column;
	border-radius: 12px;
	min-height: 373px;

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 21px;
	}

	&__action-buttons {
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin-bottom: 42px;
	}

	&__back-button {
		display: flex;
		align-items: center;
		font-weight: 400;
		font-size: 14px;
		cursor: pointer;
		transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, background-image 0.15s ease-in-out,
			border-color 0.15s ease-in-out;

		&:hover {
			& .forgot-modal__back-text {
				color: @primary-500;
			}
		}
	}

	&__back-text {
		margin-left: 4px;
	}

	&__title {
		font-size: 24px;
		font-weight: 600;
		margin-bottom: 4px;
		font-family: @header-5-bold-family;
	}

	&__text {
		font-size: 14px;
		font-weight: @body-1-regular-weight;
		font-style: @body-1-regular-style;
		font-family: @body-1-regular-family;
		color: @gray-500;
		line-height: 1.7;
	}

	&__field {
		min-height: 51px;
		margin-bottom: 21px;
	}

	&__row {
		height: 26px;
		display: flex;
		justify-content: space-between;
		margin-bottom: 21px;
		margin-top: 7px;
	}

	&__checkbox-wrapper {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
	}

	&__checkbox {
		width: 20px;
		height: 20px;
	}

	&__label {
		font-weight: 400;
		font-size: 13px;
		margin-left: 7px;
	}

	&__submit-button {
		height: 58px;
		padding: 18px 20px !important;
		border-radius: 10px !important;
		background: @primary-500 !important;
		color: @white-500 !important;
		font-size: 14px !important;
		font-weight: @body-1-bold-weight !important;
		font-style: @body-1-bold-style !important;
		font-family: @body-1-bold-family !important;
		margin-right: 0 !important;
	}

	&__footer {
		text-align: center;
		font-size: 14px;
		font-weight: 400;
		color: @dark-90;
		margin-top: 21px;
	}

	&__link {
		font-size: 14px;
		font-weight: 400;
		color: @primary-500 !important;

		&_bold {
			font-weight: bolder;
		}
	}
}
