@import '../../../../semantic-ui/variables.less';

.modes {
	&__row {
		margin: 0 !important;
		padding-top: 0 !important;
		padding-bottom: 14px !important;

		&:last-child {
			padding-bottom: 0 !important;
		}
	}

	&__column {
		font-size: 13px;
		font-weight: @label-regular-weight;
		display: flex !important;
		justify-content: flex-end;
		align-items: center;
	}

	&__text {
		font-size: 13px;
		font-weight: @label-regular-weight;
		white-space: nowrap;
	}

	&__mode {
		width: 90px !important;
	}

	&__validate {
		display: flex !important;
		width: 140px !important;
		align-items: center;
		gap: 9px;
		margin-left: 10px;
	}

	&__date {
		font-size: 13px;
		display: flex !important;
		width: 250px !important;
		align-items: center;
		gap: 9px;
	}

	&__button-row {
		display: flex;
		justify-content: flex-end;
		margin: auto;
		width: 740px;
		margin-bottom: 20px;
		margin-top: 5px;

		&-text {
			font-size: 13px;
			margin-top: 2px;
		}

		img {
			cursor: pointer;
			margin-left: 10px;
		}
	}

	&__column_close {
		margin-top: 4px;
		width: 24px;
		height: 24px;
		background: url('../../../../components/images/close.svg') no-repeat;
		background-size: 100%;
		cursor: pointer;

		&:hover {
			width: 24px;
			height: 24px;
			background: url('../../../../components/images/close-hover.svg') no-repeat;
			background-size: 100%;
		}
	}

	&__dropdown {
		width: 90px !important;
		position: static !important;
	}
	&__dropdown .menu {
		width: 90px !important;
		min-width: 90px !important;
	}
	
	&__dropdown .text {
		white-space: nowrap;
	}

	&__error-message {
		color: @color-option-3;
		font-size: 11px;
		font-weight: 600;
		padding-top: 3px;
	}
}
