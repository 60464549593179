@import '../../../../semantic-ui/variables.less';

.chat-modal {
	height: 612px;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	font-family: Montserrat;
	font-size: 12px;
	font-weight: 400;

	&__content {
		height: 564px;
		overflow-y: auto;
	}

	&__date {
		display: block;
		text-align: center;
		margin-top: 16px;
	}

	&__footer {
		display: flex;
		padding: 0 20px;
		height: 48px;
		border-top: 1px solid @border-color;
	}

	&__field {
		width: 785px;
	}

	&__input {
		width: 100%;
		height: 100%;
		border: none;
		outline: none;
		font-size: 13px;
	}

	&__input::placeholder {
		font-size: 13px;
	}

	&__message {
		position: relative;
		padding: 4px 8px;
		border-radius: 6px;
		border-top-left-radius: 0;
		width: max-content;
		margin-bottom: 5px;

		&_foreign {
			margin-left: 32px;
			background: @light-40;
		}

		&_my {
			margin-left: auto;
			margin-right: 20px;
			background: @primary-500;
			color: @white-500;
			border-top-left-radius: 6px;
		}
	}

	&__message_foreign:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: -9px;
		width: 9px;
		height: 11px;
		border-radius: 4px;
		border: 9px solid transparent;
		border-top: 9px solid @light-40;
		border-right: 9px solid @light-40;
	}

	&__message_my:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		right: -9px;
		width: 9px;
		height: 11px;
		border-radius: 4px;
		border: 9px solid transparent;
		border-left: 9px solid @primary-500;
		border-top: 9px solid @primary-500;
	}

	&__message-content {
		padding-right: 40px;
	}

	&__icon {
		width: 40px;
		height: 47px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		&:last-child {
			margin-left: 30px;
		}
	}

	&__text {
		display: block;
		font-size: 13px;
		white-space: break-spaces;
		overflow-wrap: break-word;
		max-width: 760px;

		&_small {
			font-size: 11px;
		}

		&_bold {
			display: inline-block;
			font-weight: 600;
		}

		&_message {
			margin-top: 15px;
		}
	}

	&__time {
		display: block;
		font-weight: 300;
		text-align: right;
	}

	&__message_my {
		& .chat-modal__message-content {
			padding-right: 56px;
		}

		& .chat-modal__time {
			padding-right: 20px;
		}

		& .chat-modal__text_message {
			margin-top: 0;
		}
	}

	&__check-icon {
		position: absolute;
		right: 9px;
		bottom: 9px;

		&_last {
			right: 4px;
		}
	}
}
