@import "../../../../semantic-ui/variables.less";

.carrier-modal-custom-cell {
    &__company {

        &-name {
            font-size: 13px;
            font-weight: 600;
        }

        &-code {
            font-size: 11px;
            font-weight: 500;
        }
    }


    &__price,
    &__date {
		display: flex;
		align-items: center;
		position: relative;
		gap: 10px;

		&-label {
			font-size: 12px;
			font-weight: 500;
		}
	}

    &__button {
        border: 1px solid @color-option-1;
        border-radius: 12px !important;
        font-size: 13px;
        padding: 8px 20px;
        color: @dark-500 !important;
        line-height: 1.5;
        cursor: pointer;
        font-weight: 500;
        transition: color .15s ease-in-out, background-color .15s ease-in-out, background-image .15s ease-in-out, border-color .15s ease-in-out;
        width: fit-content;

        &:hover {
            color: @white-500 !important;
            background-color: @color-option-1;
        }

        &-wrapper {
            display: flex;
            justify-content: right;
        }
    }
}
