@import '../../../../../semantic-ui/variables.less';

.company-toggle-content {
	padding: 30px;
	display: flex;
	flex-direction: column;
	gap: 21px;
	border-radius: 12px;
	height: 272px;

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__close-button {
		width: 40px;
		cursor: pointer;
	}

	&__title {
		font-size: 24px;
		font-weight: 600;
		margin-bottom: 4px;
		font-family: @header-5-bold-family;
	}

	&__text {
		font-size: 14px;
		font-weight: @body-1-regular-weight;
		font-style: @body-1-regular-style;
		font-family: @body-1-regular-family;
		color: @gray-500;
		line-height: 20px;
	}

	&__buttons-wrapper {
		display: flex;
		border: 1px solid @primary-500;
		border-radius: 10px;
		padding: 8px;
	}

	& &__button {
		width: 50%;
		padding: 8px 20px;
		border-radius: 8px;
		font-size: 13px;
		font-weight: 500;
		text-align: center;
		cursor: pointer;
		font-family: @body-1-regular-family;
		color: @primary-500;
		background: @white-500;
		transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, background-image 0.15s ease-in-out,
			border-color 0.15s ease-in-out;

		&_active {
			color: @white-500;
			background: @primary-500;
		}
	}

	&__submit-button {
		padding: 20px !important;
		border-radius: 10px !important;
		background: @primary-500 !important;
		color: @white-500 !important;
		font-size: 14px !important;
		font-weight: @body-1-bold-weight !important;
		font-style: @body-1-bold-style !important;
		font-family: @body-1-bold-family !important;
	}
}
