@import '../../../semantic-ui/variables.less';

.validation_error {
	color: @color-option-3;
}

.input_row {
	label {
		font-size: 12px;
		line-height: 12px;
		min-height: 12px;
		margin-bottom: 6px;
	}

	textarea {
		min-height: 50px;
		font-size: 13px;
		font-weight: 500;
		background: @white-500 !important;
		outline: none !important;
		padding: 6px 12px !important;
		border: 1px solid rgba(34, 36, 38, 0.15);
		color: rgba(0, 0, 0, 0.67);
		border-radius: 4px;
		font-family: @header-7-bold-family, sans-serif !important;
	}

	textarea::-webkit-input-placeholder {
		color: lightgray !important;
	}

	textarea:focus, textarea:hover {
		border: 1px solid @gray-500 !important;
		background: @white-500 !important;
		transition: background-color 0.3s !important;
	}

	textarea::placeholder {
		color: rgba(0, 0, 0, 0.25) !important;
		font-weight: 400;
	}

	&.error textarea {
		border: 1px solid @color-option-3 !important;
	}

	&.disabled textarea {
		background-color: #1015130d !important;
	}
}
