.reqs-custom-modal-cell {
	&__cell {
		&_bold {
			font-size: 13px;
			font-weight: 600;
		}

		&_thin {
			font-size: 12px;
			font-weight: 400;
		}
	}

	&__tender {
		&-tk{
			font-size: 12px;
		}
	}

	&__address,
	&__done-at {
		display: flex;
		flex-direction: column;
		gap: 10px;
		font-size: 12px;
		font-weight: 400;

		&-block-item {
			display: flex;
			align-items: center;
			gap: 5px;
			position: relative;
		}

		&-status-indicator {
			position: absolute;
			left: -19px;
		}

		&-label {
			font-size: 12px;
			font-weight: 600;
		}

		&-name {
			font-size: 14px;
			font-weight: 600;
		}
	}

	&__product-invoice,
	&__chosen_comment,
	&__flight {
		width: 100%;
		display: flex;
		gap: 6px;

		&-note,
		&-number {
			font-size: 12px;
			font-weight: 600;
		}

		&-icon {
			width: 17px;
			margin-bottom: 2px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}

		&-image {
			width: 12px;
			height: 12px;
		}
	}

	&__sum {
		&-block {
			display: flex;
			align-items: center;
			position: relative;
		}

		&-offer-info {
			position: absolute;
			left: -34px;
		}
	}

	&__features {
		display: flex;
		gap: 16px;
	}

	&__feature {
		cursor: pointer;
	}

	&__icon {
		width: 22px;
		height: 23px;

		&_not_load {
			background-image: url('../../../../components/icons/not_load.svg');
		}

		&_not_turn {
			background-image: url('../../../../components/icons/not_turn.svg');
		}

		&_fragile {
			background-image: url('../../../../components/icons/fragile.svg');
		}
	}

	&__cargo {
		width: 100%;
		display: flex;
		gap: 22px;
	}

	&__cargo-item {
		position: relative;
		width: 140px;
		display: flex;
		gap: 10px;
		cursor: pointer;
	}

	&__cargo-image {
		width: 24px;
		height: 24px;
		margin-top: 4px;
	}

	&__cargo-field {
		width: 80px;
		height: 30px;
		line-height: 30px;
		padding: 0 5px;
		border: 1px solid #ced4da;
		border-right: none;
		text-align: center;
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&__size {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 10px;
	}

	&__size-row {
		width: 100%;
		display: flex;
		gap: 22px;
	}
}
