@import '../../../../semantic-ui/variables.less';

.info-wrapper {
	display: flex;
	gap: 25px;

	&__right-section {
		width: 100%;
		padding-top: 46px;
	}

	&__left-section {
		display: flex;
		flex-direction: column;
		gap: 25px;
	}
}

@media screen and (max-width: 1330px) {
	.info-wrapper {
		gap: 10px;
	}
}
