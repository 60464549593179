.req-filter-component {
    padding-bottom: 10px;

    &__row {
        padding: 0 0 12px 0 !important;
        font-size: 13px;

        &_with-label {
            padding: 0 0 6px 0 !important;
        }

        &-label {
            font-size: 13px;
            font-weight: 400;
        }
    }

    & hr {
        border-color: transparent;
    }

    &__column {
        &_with-group {
            display: grid !important;
            grid-template-columns: calc(100% - 103px) 103px;
            align-items: end;
            &-checkboxes {
                display: flex;
                flex-direction: column;
                row-gap: 5px;
            }
        }
        &_with-label {
            display: flex !important;
            justify-content: space-between;
            &-checkbox {
                display: flex !important;
                gap: 15px !important;
            }
        }
    }
}

@media screen and (max-width: 1630px) {
    .req-filter-component {
        &__column {
            &_with-label {
                display: flex !important;
                flex-direction: column;
                gap: 10px;
                &-checkbox {
                    display: flex !important;
                    gap: 15px !important;
                }
                &-button {
                    display: flex;
                    justify-content: right;
                }
            }
        }
    }
}