@import '../../../../semantic-ui/variables.less';

.size-component {
	padding-top: 14px;
	position: relative;

	&__row {
		display: flex;
		margin-bottom: 14px;

		&_double {
			flex-direction: column;
			gap: 10px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__row-with-icons {
		display: flex;
		gap: 22px;
		padding: 0 10px;
	}

	&__column {
		display: flex;
		flex-direction: column;
	}

	&__text {
		width: 40%;
		max-width: 292px;
		font-family: @body-1-regular-family;
		font-size: 13px;
		font-weight: 400;
		padding: 6px 10px;

		&_right {
			text-align: right;
		}
	}

	&__cargo-field {
		display: flex;
		gap: 10px;
	}

	&__field {
		width: 60%;
		max-width: 437px;
		padding: 0 10px;

		&_medium {
			width: 168px;
		}

		&_small {
			width: 95px;

			& .input-group-text {
				width: 25px;
				padding: 0 0 0 5px;
			}
		}
	}

	&__error {
		display: flex;
		flex-direction: column;
		margin-top: -4px;
		margin-bottom: 14px;
		text-align: center;

		div {
			display: block;
		}
	}

	&__error-message {
		color: @color-option-3;
		font-size: 11px;
		font-weight: 600;
		padding-top: 3px;
	}

	&__close {
		position: absolute;
		top: 15px;
		right: 135px;
		width: 24px;
		height: 24px;
		background: url('../../../../components/images/close.svg') no-repeat;
		background-size: 100%;
		cursor: pointer;

		&:hover {
			width: 24px;
			height: 24px;
			background: url('../../../../components/images/close-hover.svg') no-repeat;
			background-size: 100%;
		}
	}

	&__button-row {
		display: flex;
		justify-content: flex-end;
		margin: auto;
		width: 560px;
		margin-bottom: 20px;
		margin-top: 5px;

		&-text {
			margin-top: 2px;
		}

		img {
			cursor: pointer;
			margin-left: 10px;
		}
	}
}
