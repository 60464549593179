@import '../../variables.less';

.ui.styled.accordion {
    border-radius: 12px !important;
    box-shadow: none !important;

    & .title {
        height: fit-content;
        line-height: 34px;
        padding: 10px 14px !important;
        color: black !important;
        border-bottom: none !important;
    }

    & .title:not(:first-child) {
        border-top: 1px solid @border-color !important;
    }

    & .title.error {
        color: red !important;
    }

    & .title.active {
        border-bottom: none !important;
    }

    & .content.active {
        max-height: calc(100vh - 151px) !important;
        overflow: visible !important;
        overflow-x: hidden !important;
        padding: 14px 18px;
        border: none !important;
        border-radius: 12px;
    }
}

.ui.menu {
    &:after {
        display: none !important;
    }
}

.accordion {
    border-color: @border-color !important;

    &__sub {
        border-radius: 0 !important;
        border: none !important;
        margin: 0 !important;

        & .title {
            border-top: 1px solid @border-color !important;

        }
    }

    &__title {
        width: 100%;
        display: flex;
        justify-content: space-between;

        &-text {
            font-family: @header-7-bold-family, sans-serif !important;
            font-weight: 500;
            font-size: 18px;
        }

        &-buttons {
            display: flex;
            gap: 10px;

            &_clear {
                font-family: @header-7-bold-family, sans-serif !important;
                font-size: 11px;
                cursor: pointer;
                font-weight: 500;

                &:hover {
                    text-decoration: underline;
                    color: @primary-500;
                }
            }
        }
    }

    &__title-buttons {
        align-items: center;
    }

    &__chevron-icon {
        -webkit-transition: -webkit-transform .8s ease-in-out;
        -ms-transition: -ms-transform .8s ease-in-out;
        transition: transform .2s ease-in-out;
        width: 20px;
        height: 20px;

        &.up {
            transform: rotate(-180deg);
        }

        &.down {
            transform: rotate(0deg);
        }
    }

    &__content {
        &_with-sub-accordion {
            padding: 0 !important;
        }
    }
}

@media (max-width: 425px) {
    .ui.styled.accordion {
        & .title {
            height: 48px;
            padding: 6px 0 0 8px;
            font-weight: normal;
        }

        & .content {
            padding: 0;
        }
    }
}